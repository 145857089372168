import request from '@/utils/request'

export function fetchList(query) {
    return request({
        url: '/admin/logisticsCar/list',
        method: 'get',
        params: query
    })
}

export function saveOrUpdate(data) {
    return request({
        url: '/admin/logisticsCar/saveOrUpdate',
        method: 'post',
        data
    })
}

export function selectList() {
    return request({
        url: '/admin/logisticsCar/selectList',
        method: 'get'
    })
}


export function selectAll() {
    return request({
        url: '/admin/logisticsCar/selectAll',
        method: 'get'
    })
}

export function deleteLogisticsCar(id) {
  return request({
    url: '/admin/logisticsCar/delete',
    method: 'get',
    params: { id }
  })
}